import * as React from "react";
import{ useEffect } from "react";

import { Invoice } from "../interfaces";
import Summary from "./Summary"

interface PaymentState {
  state: string,
  error: {
    code: string;
    message?: string;
  } | null;
}

interface FromProps {
  invoice: Invoice,
  paymentSessionId: string,
}

export default function Form({ invoice, paymentSessionId }: FromProps) {

  useEffect(() => {
    // Check if PaymentWindow is loaded and accessible
    if (typeof window.PaymentWindow === "undefined") {
      console.error("PaymentWindow script not loaded");
      return;
    }
    console.log(invoice.amount)
    console.log(invoice.currency)

    let shopDomain = 'develop.xn--guldbkvingrd-2cbi.dk';
    let paymentWebserviceDomain = 'develop.payment.xn--guldbkvingrd-2cbi.dk';

    const paymentwindow = new PaymentWindow({
      'merchantnumber': "8042312",
      'amount': invoice.amount * 100,
      'currency': invoice.currency,
      'minimumuserage' : 18,
      'ownreceipt' : 0,
      'callbackurl': 'https://' + paymentWebserviceDomain + '/.netlify/functions/confirm-payment?paymentSessionId=' + paymentSessionId,
      'accepturl': 'https://' + shopDomain + '/?snip-psid=' + paymentSessionId + '#/checkout',
      'cancelurl': 'https://' + paymentWebserviceDomain + '/cancel'
    });
    paymentwindow.open()
  }, []);

  const [paymentState, setPaymentState] = React.useState<PaymentState>({
    state: 'processed',
    error: null
  });

  function getErrorFromCode(
    code: string) {
    if (!code)
      return null;

    if (!paymentState.error) {
      return { code }
    }

    return {
      code,
      message: paymentState.error.message
    }
  }

  function getErrorFromMessage(message: string) {

    if (!paymentState.error) {
      return null;
    }

    return {
      message,
      code: paymentState.error.code
    }
  }

  return (
    <div>
      {/*<Summary {...invoice} />*/}
      <div id="payment-div"></div>
    </div>

  )
}
